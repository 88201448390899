<template>
	<div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-sheet color="white" elevation="0" class="pa-0 px-1">
					<p class="text-h6 mb-4">Search</p>
					<v-row>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
							<v-text-field dense clearable label="Name" v-model="filters.name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
							<v-text-field dense clearable label="NRIC" v-model="filters.nric"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
							<v-text-field dense clearable label="Mobile Number" v-model="filters.mobile_number"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
							<v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
								<v-icon small left>mdi-magnify</v-icon> Search
							</v-btn>
							<v-btn small class="elevation-0" :ripple="false" @click='reset'>
								<v-icon small left>mdi-undo</v-icon> Reset
							</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="px-4">
				<v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
					<template v-slot:item='{ item, index }'>
						<tr>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.first_name"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.last_name"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.nric"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.gender"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.citizenship"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.mobile_number"></td>
                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
								<v-menu bottom offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-btn small icon v-bind="attrs" v-on="on">
											<v-icon>mdi-dots-horizontal</v-icon>
										</v-btn>
									</template>
									<v-list dense>
										<v-list-item :to="getViewUrl(item.id)" target="_blank">
											<v-list-item-title>View Info</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row v-if="pagination.total_pages > 1">
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-pagination circle :length="pagination.num_pages" :total-visible="7" v-model="filters.page"></v-pagination>
			</v-col>
		</v-row>
	</div>
</template>


<script>

export default {
	name: 'PromoterApplicationList',
	data () {
		return {
			loading: false,
			filters: {
				name: '',
				nric: '',
				mobile_number: '',
				page: 1
			},
			statuses: [],
			items: [],
			headers: [],
			pagination: {}
		}
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/dashboard/getApplications', this.filters).then((response) => {
				this.items = response.data.items
				this.headers = response.data.headers
				this.pagination = response.data.pagination
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { name: '', nric: '', mobile_number: '', page: 1}
		},
        getViewUrl: function(id){
            return "/epanel/dashboard/application/" + id
        }
    }
}

</script>


<style scoped>
.v-sheet.v-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
</style>
